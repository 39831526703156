import { Component, Prop, Watch } from 'vue-property-decorator'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { RULES } from '@/components/forms'
import { Resource } from '@/entities/public'
import { UserData } from '@/store/user/state'
import { Crumb } from '@/store/resources/form/state'
import { FilesProcess } from '@/components/forms/view/FilesProcess'
import { fixAmountFormatted, fixPrice } from '@/utils/general'
import { Debounce } from '@/utils/decorators'
import dayjs from 'dayjs'

@Component({
  methods: {
    ...mapActions('persons', ['findPerson']),
    ...mapActions('resources', ['refresh']),
    ...mapActions('resources/form', ['closeDeal', 'removeStock', 'publishStock', 'republishStock', 'getStockSalesById']),
    ...mapActions('resources/form', ['getLeadById', 'updateDealProcessStatusAndClosingReason', 'getOpenLead']),
    ...mapActions('resources/form', ['removeData', 'setCrumbs', 'getExecutiveByPerson', 'getAppraiseWithScrapping', 'automaticAppraisal', 'getAppraisalWithAutoId']),
    ...mapMutations('resources/form', ['setBackup', 'setStaticForm']),
    ...mapMutations('persons', ['setRutInfo']),
    fixPrice,
  },
  computed: {
    ...mapGetters('resources', ['getForm', 'getResource']),
    ...mapGetters('app', ['isResize', 'isMobile']),
    ...mapGetters('resources/form', ['backup', 'crumbs']),
    ...mapGetters('user', ['user']),
  },
})
export class GForm extends FilesProcess {
  @Prop({ type: String }) returnTo?: string
  @Prop({ type: [String, Number] }) uid!: any
  @Prop({ type: [String, Number] }) id!: any
  @Prop({ type: String, default: null }) model!: string
  @Prop({ type: Number }) parentId?: number
  @Prop({ type: String }) parentModel?: string

  $refs!: {
    form: HTMLFormElement
  };

  currency = null
  getAppraisalWithAutoId!: (id: number) => Promise<any>
  automaticAppraisal!: (id: number) => Promise<any>
  getOpenLead!: (variables) => Promise<any>
  getLeadById!: (idLead: number) => Promise<any>
  getStockSalesById!: (stockId: number) => Promise<any>
  publishStock!: (stockId: number) => Promise<void>
  removeStock!: (stockId: number) => Promise<void>
  republishStock!: (stockId: number) => Promise<void>
  getAppraiseWithScrapping!: (payload: { mileage: number, idGeneration: number, idVersionYear: number }) => Promise<any>
  getExecutiveByPerson!: (id: number) => Promise<number>;
  setCrumbs!: (crumbs: Array<Crumb>) => void
  getResource!: (slug: string) => Resource
  setBackup!: (payload) => void
  closeDeal!: (payload: { idDeal: number, idClosingReason: number }) => Promise<void>
  setRutInfo!: (data: undefined) => void;
  findPerson!: ({ phone, rut, id, type }: {
    phone?: string | null
    rut?: string | null | undefined
    id?: number
    type?: string
  }) => void;

  updateDealProcessStatusAndClosingReason!: (payload: {
    id: number
    idProcessStatus: number
    idClosingReason: number
  }) => Promise<void>

  backup: any
  crumbs: Array<any>
  user!: UserData
  isMobile!: boolean
  isResize!: number
  setStaticForm!: (payload) => void
  refresh!: (payload: { force?: boolean }) => Promise<any>
  idEmployee: number = null
  containerHeight = this.$vuetify.breakpoint?.height
  rutRules = RULES.isRut;
  emailValidate = RULES.isEmail;
  phoneRequired = RULES.isPhone;
  fieldRequired = RULES.isRequired;
  validUrl = RULES.isUrl
  ppuRules = RULES.isPPU;
  amountRequired = RULES.isAmount;
  valid = false
  metadata = {}
  openAlert = false
  disableSend = false
  alertTitle = ''
  alertSubtitle = ''
  loadingForm = false
  isUploadingFile = false
  alert = {
    open: false,
    title: '',
  }

  @Watch('user', { immediate: true, deep: true })
  async onUserChange (val) {
    if (val) {
      const employee = await this.fetchData({
        query: { name: 'find', model: 'Employee' },
        filter: { person: { user: { id: { _eq: val.id } } } },
      })
      this.idEmployee = employee?.[0]?.id
    }
  }

  @Debounce(500)
  async close (files = []) {
    if (files?.length) {
      await this.removeOrphanFiles(files)
    }
    this.removeCrumb()
    this.loadingForm = false
    this.$router.back()
  }

  closeAlert () {
    this.openAlert = false
  }

  async created () {
    if (this.$refs.form?.resetValidation) {
      this.$refs.form?.resetValidation()
    }
    this.setStaticForm(true)
    this.loadingForm = false
    const { returnTo } = this

    if (returnTo) {
      const resource = this.getResource(returnTo)
      this.setCrumbs([{ resource, title: resource.description }])
    }
    window.addEventListener('notification-message', this.setLoadingMessage, false)

    const currency = await this.fetchData({
      query: { name: 'find', model: 'Currency' },
      filter: { default: { _eq: true } },
    })

    this.currency = currency?.[0]
  }

  beforeDestroy (): void {
    window.removeEventListener('notification-message', this.setLoadingMessage, false)
  }

  setLoadingMessage (event: any): void {
    if (event.detail.type === 'error') {
      this.loadingForm = false
    }
  }

  removeCrumb () {
    const { crumbs } = this

    if (crumbs.length) {
      this.setCrumbs(crumbs.slice(0, -1))
    }
  }

  async getParentData (parentModel: string, parentId: string) {
    if (!parentModel || !parentId) {
      return {}
    }

    const parent = await this.fetchData({
      query: { name: 'fetch', model: parentModel, params: { id: parentId } },
      force: true,
    })

    return { [parentModel.toLowerCase()]: parent }
  }

  async destroy () {
    this.$refs.form.reset()
    await this.refresh({ force: true })
  }

  setFormCrumbs (metadata, title, create = false) {
    const { crumbs = [] } = this

    if (!crumbs.length) return null

    this.setCrumbs([...crumbs, { resource: metadata, title: `${create ? 'Editar' : 'Crear'} ${title}` }])
  }

  setCustomCrumbs (metadata, title) {
    const { crumbs = [] } = this

    if (!crumbs.length) return null

    this.setCrumbs([...crumbs, { resource: metadata, title }])
  }

  isBreadCrumbPresent (title) {
    const { crumbs } = this

    if (!crumbs.length) return true

    return crumbs.some(crumb => crumb?.resource?.form?.title === title)
  }

  async insertFileInfo (fields) {
    await this.pushData({ model: 'File', fields })
  }

  async insertFileProcessInfo (fields) {
    await this.pushData({ model: 'FileProcess', fields })
  }

  async changeValidFileProcess (file, valid = true) {
    if (file?.valid) return

    await this.insertFileProcessInfo({
      id: file?.id,
      valid,
      validation_type: 'manual',
      comment: valid ? '' : file.comment,
    })
  }

  async getCurrencyValueExchange (base, currency) {
    if (!base) return 0
    if (!currency) {
      throw new Error('System Currency not found')
    }

    if (base.currency?.id === currency?.id) {
      return base.amount
    }

    const exchangeRate = await this.fetchData({
      query: { name: 'find', model: 'ExchangeRate' },
      filter: {
        _and: [
          { date_to: { _is_null: true } },
          { id_currency_from: { _eq: base.currency.id } },
          { id_currency_to: { _eq: currency.id } },
        ],
      },
    })

    if (!exchangeRate.length) {
      throw new Error(`Exchange Rate from: ${base.currency.id} not found and to: ${currency.id} not found`)
    }

    return Math.round(base.amount * exchangeRate[0].rate)
  }

  async insertCavValidation (id, file, isValid, comment = '') {
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: {
        _and: [
          { process: { table_name: { _eq: 'appraisal' } } },
          { status: { name: { _eq: 'valid_cav' } } },
        ],
      },
    })
    await this.pushData({
      model: 'Appraisal',
      fields: { id, id_process_status: status[0].id },
    })

    await this.insertFileProcessInfo({
      id: file[0].id,
      comment,
      valid: isValid,
      validation_type: 'manual',
    })
  }

  transformData (transform, value) {
    if (!value) return null

    if (transform === 'date') {
      return dayjs(value).format('DD/MM/YYYY')
    }

    if (transform === 'amount') {
      return fixPrice(value)
    }

    if (transform === 'numeric') {
      return fixAmountFormatted(value)
    }

    return value
  }

  async goToConcessionaire (model) {
    const { brand: { id: idBrand }, id } = model

    const concessionaire = await this.fetchData({
      query: { name: 'find', model: 'Concessionaire' },
      filter: { id_brand: { _eq: idBrand } },
    })

    const linkModel = concessionaire?.[0]?.details?.find(({ idModel }) => idModel === id)

    if (linkModel) {
      return this.openLink(linkModel.value)
    }

    const defaultLink = concessionaire?.[0]?.details?.find(({ idModel }) => idModel === null)

    this.openLink(defaultLink.value)
  }
}
